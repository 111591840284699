import userData from "../../services/user-data";
import helper from "@/helper";
import firebaseMessaging from "../../../../firebase"
import notification from "@/common/services/notification";

export default {
  data() {
    return {
      messaging: firebaseMessaging,
      generatedToken: null,
      login: {
        login_by: 'phone',
        email: null,
        opt: null,
        country: "",
      },
      phoneCodes: [],
      otpSent: false,
      formValidations: {
        email: v => {
          if (this.login.login_by == 'email') {
            return v ? (/.+@.+/.test(v) || "E-mail must be valid") : "E-mail is required"
          }
          return true
        },
        country: v => {
          if (this.login.login_by == 'phone') {
            return !!v || "Country is required"
          }
          return true
        },
        phone: v => {
          if (this.login.login_by == 'phone') {
            return v ? (/^\d+$/.test(v) || 'Phone must be valid') : "Phone is required"
          }
          return true
        },
        otp: v => {
          if (this.otpSent) {
            return v ? (/^(?=.{6,})/.test(v) || 'OTP must be 6 digits') : "OTP is required"
          }
          return true
        }
      }
    }
  },
  created() {
    if (this.messaging != null) {
      this.requestNotificationPermission();
      this.messaging.onMessage((payload) => {
        console.log('[firebase-messaging-sw.js] Received foreground message ', payload);
        notification.successNotification(payload);
      });
    }


    if (this.$store.state.user != null) {
      this.$popper.info("You already login");
      this.$router.push({
        name: 'home'
      });
    }
  },
  mounted() {
    //
  },
  methods: {

    submitForm() {
      if (!this.otpSent) {
        this.showLoader(true);
        userData.loginSendOTP(this.login).then(response => {
          this.$popper.success(response.data.message)
          this.hideLoader()
          this.otpSent = true;
          if (this.login.login_by == "email") {
            document.getElementById('phoneLink').classList.add('no-click');
          } else {
            document.getElementById('emailLink').classList.add('no-click');
          }
        });
      } else {
        this.showLoader(true);
        userData.login(this.login).then(response => {
          this.$popper.success(response.data.message)
          this.hideLoader()
          // redirect to dashboard page
          if (response.data.token) {
            this.$store.bus.emit("authToken", {
              'access_token': response.data.token,
              'expires': null
            });
            helper.rememberAuthorization();
            this.$store.bus.on("user", (value) => {
              if (value) {
                setTimeout(() => {
                  this.$router.push({
                    name: 'dashboard-home'
                  });
                }, 1000);
              }
              this.getNotificationToken();
            });
            helper.validateAuthorization();
          }
        });
      }
    },
    changeTab(tab) {
      this.login.login_by = tab;
      if (tab == 'email') {
        this.login.phone = null
      } else {
        this.login.email = null
      }
    },
    resendOtp() {
      this.showLoader(true);
      userData.loginSendOTP(this.login).then(response => {
        this.$popper.info(response.data.message)
        this.hideLoader()
      });
    },
    changeEmailPhone() {
      this.otpSent = !this.otpSent
      var emailClass = document.getElementById('emailLink').classList;
      var phoneClass = document.getElementById('phoneLink').classList;
      if (this.login.login_by == "email") {
        emailClass.add("bg-highlight", "no-click");
        phoneClass.remove("no-click");
      } else {
        phoneClass.add("bg-highlight", "no-click");
        emailClass.remove("no-click");
      }
    },
    requestNotificationPermission() {
      Notification.requestPermission((result) => {
        if (result === 'denied') {
          alert('Please allow notification in Browser to receive alerts');
        } else if (result === 'default') {
          alert('Please allow notification in Browser to receive alerts');
        }
        console.log('Permission was granted for notifications');
        this.messaging.getToken().then((token) => {
          if (token) {
            this.generatedToken = token;
          }
        })
      });
    },

    getNotificationToken() {
      if (this.generatedToken) {
        userData.updateNotificationToken({
          'token': this.generatedToken
        }).then(() => {
        });
      } else {
        console.log("Not Get Any Token")
      }

    }
  }
}