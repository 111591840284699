export default {
    props: ['group', 'fieldData'],
    emits: ['back', 'submit'],
    data() {
        return {
            postData: {},
            types: {
                'input_file': 'file',
                'input_text': 'text',
                'input_number': 'number',
            },
            formValidations: {}
        }
    },
    created() {
        this.formValidations = this.registrationFieldValidationRule(this.fieldData);
        /** Based on fields populate formValidations object here */
    },
    methods: {
        header(key) {
            return {
                title: key,
                subtite: "Resolve this key"
            }
        },
        changeEvent(e) {
            if (e.target.files) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                // this.postData[e.target.name] = files[0]; 
                // console.log(this.postData);
            }
        },
        back() {
            this.$emit('back');
        },
        submitForm() {
            this.$emit('submit', this.postData);
        }
    }
}