<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Partner Account Request</h1>
    <a href="#" data-menu="account-request-help" class="header-icon header-icon-2">
        <i class="fa fa-question-circle font-15"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content registerContainer">
    <div class="card card-style">
        <div class="content">
            <Form novalidate @submit="submitForm" :validation-schema="formValidations" v-slot="{ errors }"
                autocomplete="off">
                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.first_name}">
                    <i class="fa fa-user"></i>
                    <Field as="input" type="text" name="first_name" v-model="registration.first_name"
                        class="form-control" id="firstName" placeholder="First Name"></Field>
                    <label for="firstName" class="color-primary-dark font-13 font-600">First Name</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.first_name}}
                    </template>
                </form-control>

                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.last_name}">
                    <i class="fa fa-user"></i>
                    <Field as="input" type="text" name="last_name" v-model="registration.last_name" class="form-control"
                        id="lastName" placeholder="Last Name"></Field>
                    <label for="lastName" class="color-primary-dark font-13 font-600">Last Name</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.last_name}}
                    </template>
                </form-control>

                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.email}">
                    <i class="fa fa-at"></i>
                    <Field as="input" name="email" type="email" v-model="registration.email" class="form-control"
                        id="email" placeholder="Email Address"></Field>
                    <label for="email" class="color-primary-dark font-13 font-600">Email Address</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.email}}
                    </template>
                </form-control>

                <div class="input-group  raw">
                    <div class="col-4">
                        <form-control class="validate-field input-style-active"
                            :class="{'is-invalid' : errors.country}">
                            <app-select name="country" placeholder="Country"
                                :options="$store.state && $store.state.selectData && $store.state.selectData.phoneCodes" value-prop="id"
                                v-model="registration.country">
                                <template v-slot:default="slotProps">
                                    {{ slotProps.item.emoji }} &nbsp;(+{{ slotProps.item.phone_code }})
                                </template>
                                <template v-slot:display="slotProps">
                                    <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                                    {{ slotProps.item.name }} <span class="">(+{{ slotProps.item.phone_code }})</span>
                                </template>
                            </app-select>
                            <template v-slot:error>{{errors.country}}</template>
                        </form-control>
                    </div>
                    <div class="col ms-2">
                        <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.phone}">
                            <i class="fa fa-mobile"></i>
                            <Field as="input" type="number" name="phone" v-model="registration.phone"
                                class="form-control validate-number" id="phone" placeholder="Phone No"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                maxlength="10"></Field>
                            <label for="phone" class="color-primary-dark font-13 font-600">Phone No</label>
                            <em>(required)</em>
                            <template v-slot:error>
                                {{errors.phone}}
                            </template>
                        </form-control>
                    </div>
                </div>
                <div v-if="otpSent">
                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.otp}">
                        <i class="fa fa-lock"></i>
                        <Field as="input" name="otp" type="number" v-model="registration.otp" class="form-control"
                            id="otp" placeholder="Enter Email Verification Code"></Field>
                        <label for="otp" class="color-primary-dark font-13 font-600">Email Verification</label>
                        <em>(required)</em>
                        <template v-slot:error>
                            {{errors.otp}}
                        </template>
                    </form-control>
                </div>
                <div class="d-flex justify-content-between" v-if="otpSent">
                    <button @click="resendOtp"
                        class="btn btn-s btn-ripple btn-full font-600 font-13 primary-alt-light-bg letterSpacing rounded-s">
                        Resend Code
                    </button>
                    <button
                        class="btn btn-s btn-ripple btn-full font-600 font-13 primary-light-bg letterSpacing rounded-s">
                        Verify Code
                    </button>
                </div>
                <div class="d-flex justify-content-center" v-else>
                    <button 
                        class="btn btn-s btn-ripple btn-full font-600 font-13 primary-light-bg letterSpacing rounded-s">
                        Send Email OTP
                    </button>
                </div>
            </Form>
            <!-- <div class="d-flex justify-content-between mt-4">
                <button type="submit" class="btn btn-s font-600 gradient-highlight rounded-s"> <i class="fa fa-chevron-left me-2"></i>Prev</button>
                <button type="submit" class="btn btn-s font-600 gradient-highlight rounded-s">Next <i class="fa fa-chevron-right ms-2"></i></button>
            </div> -->

        </div>
    </div>

</div>
<div id="account-request-help" class="menu menu-box-modal rounded-0" data-menu-width="cover" data-menu-height="cover">
    <a href="#" class="close-menu icon icon-xs rounded-xl bg-red-dark mt-3 float-end me-3"><i
            class="fa fa-times"></i></a>
    <div class="card-center d-flex justify-content-center">
        <div class="maxWidth">
            <div class="content">
                <h1 class="font-36 font-500 primary-alt-base-text letterSpacing mb-3 lh-base">3 Steps to Register</h1>
                <p class="mt-2 font-600 font-14 letterSpacing primary-base-text">Your account will be active after below
                    steps completions.</p>
                <p>
                <ol>
                    <li class="text-dark letterSpacing"><strong class="primary-alt-light-text">Email
                            Verification</strong>: Upon account request form submission , you will receive email
                        verification code.</li>
                    <li class="text-dark letterSpacing"><strong class="primary-alt-light-text">Business
                            Details</strong>: After email code verification , a registration form will open & you need
                        to fill-up all the details along with required documents and submit with unique OTP received on
                        your phone. At this point we will receive your registration request successfully.</li>
                    <li class="text-dark letterSpacing"><strong class="primary-alt-light-text">Account
                            Approval</strong>: We will verify your business details and documents provided. If all good
                        then we will approve the account request and you will receive email notification for the same.
                        Now you can login to your account following this <a
                            href="https://partner.lifely.world/user/login">Link</a>.</li>
                </ol>

                </p>
                <a href="javascript:void(0)"
                    class="btn btn-m w-100 btn-ripple font-600 close-menu primary-light-bg letterSpacing">Ok,Thanks</a>
            </div>
        </div>
    </div>
</div>