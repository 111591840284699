import userData from "../../services/user-data"

export default {
    data() {
        return {
            otpSent: false,
            registration: {
                first_name: null,
                last_name: null,
                email: null,
                country: "",
                otp: null,
            },
            phoneCodes: [],
            formValidations: {
                first_name: v => !!v || "First Name is required",
                last_name: v => !!v || "Last Name is required",
                email: v => v ? (/.+@.+/.test(v) || "E-mail must be valid") : "E-mail is required",
                country: v => !!v || "Country is required",
                phone: v => v ? (/^\d+$/.test(v) || 'Phone must be valid') : "Phone is required",
                otp: v => {
                    if (this.otpSent) {
                        return v ? (/^(?=.{6,})/.test(v) || 'Email Code must be 6 digits') : "Email Code is required"
                    }
                    return true
                }
            },
        }
    },
    created() {
        if (this.$store.state.user != null) {
            this.$popper.info("You are already logged in");
            this.$router.push({
                name: 'dashboard-home'
            });
        }
    },
    mounted() {
        //
    },
    methods: {
        submitForm() {
            this.showLoader(true);
            let message = "Sending Email Verification Code...";
            if (this.registration.otp) {
                message = "Verifying email code...";
            }
            this.$popper.wait(message);
            userData.requestRegistration(this.registration).then(response => {
                console.log(response);
                this.$popper.hide();
                this.hideLoader();
                if (response.data.message) {
                    this.$popper.success(response.data.message);
                }
                this.otpSent = response.data.otpSent;
                if (response.data.link) {
                    window.location.href = response.data.link;
                }
            });
        },

        resendOtp() {
            this.registration.otp= "";
            this.otpSent = false;
            this.submitForm();
        }
    }
}