import api from "@/common/services/api";

export default new class {
    baseUrl = "";

    getSelectData(source) {
        return api.instance.get(this.baseUrl + "/get-select-data", {
            params: source
        });
    }
    requestRegistration(registration) {
        return api.instance.post(this.baseUrl + "/register", registration);
    }
    getRegistrationFields(query) {
        return api.instance.get(this.baseUrl + "/check-register-link", {
            params: query
        });
    }
    register(registration) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(registration)) {
            formData.append(key, Array.isArray(value) ? value[0] : value);
        }
        return api.instance.post(this.baseUrl + "/register-partner", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    resendOtp(data) {
        return api.instance.post(this.baseUrl + "/resend-otp", data);
    }

    async loginSendOTP(data) {
        // await api.instance.get("sanctum/csrf-cookie");
        return api.instance.post(this.baseUrl + "/login-send-otp", data);
    }
    async login(data) {
        // await api.instance.get("sanctum/csrf-cookie");
        return api.instance.post(this.baseUrl + "/login", data);
    }
    async userData() {
        // await api.instance.get("sanctum/csrf-cookie");
        return api.instance.get(this.baseUrl + "/user-data");
    }
    getProfileDetail(data) {
        return api.instance.get("/profile", {
            params: data
        });
    }
    updateNotificationToken(data) {
        return api.instance.post("/notification-token", data);
    }

    updateProfile(id, data) {
        return api.instance.put("/profile/"+id, data);
    }
    
    emailPhoneSendOtp(data) {
        return api.instance.post("/email-phone-send-otp", data);
    }

    emailPhoneUpdate(data) {
        return api.instance.post("/email-phone-update", data);
    }
};