<Form novalidate @submit="submitForm" :validation-schema="formValidations" v-slot="{ errors }" autocomplete="off">
    <div class="col-12 mb-3">

        <p class="color-highlight mb-0">
            {{header(group).subtitle}}
        </p>
    </div>
    <div v-for='(field, index) in fieldData' :key='field.id'>
        <label v-if="field.type == 'input_file'" class="color-primary-dark">{{ getLabel(field.name) }}</label>
        <form-control class="validate-field" :class="{'is-invalid' : errors[field.name]}">
            <Field as="input" :name="field.name" :type="types[field.type]" v-on:change="changeEvent" v-model="postData[field.name]" class="form-control" :id="field.name+'_field'" :placeholder="getLabel(field.name)"></Field>
            <label :for="field.name+'_field'" v-if="field.type != 'input_file'" class="color-primary-dark">
                {{ getLabel(field.name) }}
            </label>
            <em>(required)</em>
            <template v-slot:error>
                {{errors[field.name]}}
            </template>
        </form-control>
    </div>
    <div class="mt-4">
        <button type="button" class="btn btn-s btn-ripple font-600 primary-light-bg rounded-s float-start" @click="back"> <i class="fa fa-chevron-left me-2"></i>Prev</button>
        <button class="btn btn-s btn-ripple font-600 primary-light-bg rounded-s float-end" type="submit">Next <i class="fa fa-chevron-right ms-2"></i></button>
    </div>
</Form>