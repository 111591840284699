<app-header>
    <h1 class="header-title ms-5 text-capitalize" style="letter-spacing:0.5px;">Login</h1>
</app-header>

<div class="page-title-clear"></div>
<div class="page-content loginContainer">
    <div class="card card-style">
        <div class="tab-controls tabs-small" data-highlight="bg-primary-light-color" v-show="!otpSent">
            <a href="#" data-active data-bs-toggle="collapse" id="phoneLink" @click="changeTab('phone')"
                class="btn-ripple tabTitle" data-bs-target="#tab-1">With Phone</a>
            <a href="#" data-bs-toggle="collapse" id="emailLink" class="btn-ripple tabTitle" @click="changeTab('email')"
                data-bs-target="#tab-2">With Email</a>
        </div>
    </div>
    <div class="card card-style loginWrapper">
        <div class="content">

            <Form novalidate @submit="submitForm" :validation-schema="formValidations" v-slot="{ errors }"
                autocomplete="off">
                <div class="card bg-theme pb-0 mb-0">
                    <div id="tab-group-6">

                        <div class="clearfix mb-2"></div>
                        <div data-bs-parent="#tab-group-6" class="collapse show" id="tab-1">
                            <div class="input-group raw">
                                <div class="col-4">
                                    <div>
                                        <form-control class="validate-field input-style-active"
                                            :class="{'is-invalid' : errors.country}">
                                            <app-select name="country" :disabled="otpSent" placeholder="Country"
                                                :options="$store.state && $store.state.selectData && $store.state.selectData.phoneCodes"
                                                value-prop="id" v-model="login.country">
                                                <template v-slot:default="slotProps">
                                                    {{ slotProps.item.emoji }} &nbsp;(+{{ slotProps.item.phone_code }})
                                                </template>
                                                <template v-slot:display="slotProps">
                                                    <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                                                    {{ slotProps.item.name }} <span class="">(+{{
                                                        slotProps.item.phone_code }})</span>
                                                </template>
                                            </app-select>
                                            <template v-slot:error>{{errors.country}}</template>
                                        </form-control>
                                    </div>
                                </div>
                                <div class="col ms-2">
                                    <form-control class="validate-field has-icon"
                                        :class="{'is-invalid' : errors.phone}">
                                        <i class="fa fa-mobile"></i>
                                        <Field as="input" :disabled="otpSent" type="number" name="phone"
                                            v-model="login.phone" class="form-control validate-name" id="phone"
                                            placeholder="Phone No" maxlength="15"></Field>
                                        <label for="phone" class="color-primary-dark font-13 font-600 rounded-m">Phone
                                            No</label>
                                        <em>(required)</em>
                                        <template v-slot:error>
                                            {{errors.phone}}
                                        </template>
                                    </form-control>
                                </div>
                            </div>
                        </div>
                        <div data-bs-parent="#tab-group-6" class="collapse" id="tab-2">
                            <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.email}">
                                <i class="fa fa-at"></i>
                                <Field as="input" type="email" name="email" :disabled="otpSent" v-model="login.email"
                                    class="form-control" id="email" placeholder="Email"></Field>
                                <label for="email" class="color-primary-dark font-13 font-600 rounded-m">Email</label>
                                <em>(required)</em>
                                <template v-slot:error>
                                    {{errors.email}}
                                </template>
                            </form-control>
                        </div>
                        
                    </div>
                </div>
                <div v-if="!otpSent" class="d-grid">
                    <button class="btn btn-s btn-ripple font-600 font-13 primary-light-bg letterSpacing rounded-s">Send
                        OTP</button>
                </div>
                <div v-if="otpSent" class="d-flex justify-content-between mb-3">

                    <button class="btn btn-s primary-alt-light-bg btn-ripple font-400 letterSpacing"
                        @click="changeEmailPhone">change {{
                        login.login_by }}</button>
                    <button type="button" class="btn btn-s btn-ripple primary-light-bg font-400 letterSpacing"
                        @click="resendOtp">Resend
                        OTP</button>
                </div>
                <div v-if="otpSent">
                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.otp}">
                        <i class="fa fa-lock"></i>
                        <Field as="input" name="otp" type="number" v-model="login.otp" class="form-control" id="otp"
                            placeholder="otp"></Field>
                        <label for="otp" class="color-primary-dark font-13 font-600">OTP</label>
                        <em>(required)</em>
                        <template v-slot:error>
                            {{errors.otp}}
                        </template>
                    </form-control>

                    <div class="d-grid">
                        <button
                            class="btn btn-l btn-ripple font-600 font-14 primary-light-bg rounded-s letterSpacing">Login</button>
                    </div>
                </div>
            </Form>


        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center font-15">
            <router-link :to="{'name': 'request'}" class="primary-alt-base-text">Want to be a Partner?</router-link>
        </div>
    </div>
</div>