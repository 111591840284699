import userData from "../../services/user-data";
import helper from "@/helper";

export default {
  data() {
    return {
      generatedToken: null,
    }
  },
  created() {
    if (this.$store.state.user != null) {
      this.$popper.info("You already login");
      this.$router.push({
        name: 'home'
      });
    }
  },
  mounted() {
    //
    this.initiateImpersonation();
  },
  methods: {
    initiateImpersonation() {
      this.showLoader(true);
      this.$store.bus.emit("authToken", {
        'access_token': this.$route.query.token,
        'expires': null
      });
      helper.rememberAuthorization();
      userData.userData().then(response => {
        this.$store.bus.emit('user', response.data.data);
        this.$router.push({
          name: 'dashboard-home'
        });
      });
    },
  }
}