import userData from "../../services/user-data"
import RegistrationGroup from "../../components/RegistrationGroup"
import router from '@/router'

export default {
    components: {
        RegistrationGroup
    },
    data() {
        return {
            currentTab: 1,
            processComplete: 10,
            customFields: {},
            citiesData: [],
            registration: {
                personal: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    country: null,
                    phone: null,
                    // password: null
                },
                business: {
                    business_name: null,
                    address: null,
                    zipcode: null,
                    city: null,
                    state: null,
                    country: null,
                    website: null
                },
                otp: {
                    phone_otp: null,
                    agree: false
                },
                groupData: {}
            },
            selectDataSource: [],
            formValidations: {
                personal: {
                    first_name: v => !!v || "First Name is required",
                    last_name: v => !!v || "Last Name is required",
                    email: v => v ? (/.+@.+/.test(v) || "E-mail must be valid") : "E-mail is required",
                    country: v => !!v || "Country is required",
                    phone: v => !!v || "Phone is required",
                    // password: v => v ? 
                    //         ((/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/).test(v)
                    //             || 'Password must be 6 characters long and allow at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special letter.') 
                    //         : "Password is required",
                },
                business: {
                    business_name: v => !!v || "Name is required",
                    business_address: v => !!v || "Address is required ",
                    business_zipcode: v => v ? (/^(?=.{6,})/.test(v) || 'Zipcode must be 6 digits') : "Zipcode is required",
                    state: v => !!v || "State is required",
                    city: v => !!v || "City is required",
                    business_country: v => !!v || "Country is required",
                    // business_website: v => !!v || "Website is required",
                },
                otp: {
                    phone_otp: v => !!v || "OTP is required",
                    agree: v => !!v || "Agreement is required",
                }
            }
        }
    },
    computed: {
        'totalTabs': function () {
            return Object.keys(this.customFields).length + 3;
        },
        'progress': function () {
            return (((this.currentTab) * 100) / this.totalTabs);
        }
    },
    watch: {
        'registration.business.state'(selected) {
            console.log(selected);
            this.registration.business.city = "";
            if (selected) {
                this.citiesData = this.selectDataSource.cities.filter((value) => {
                    return (value.state_id == selected)
                })
            } else {
                this.citiesData = [];
            }

        },
        // 'registration.business.city'(selected) {
        //     if (selected) {
        //         for (var key in this.selectDataSource.cities) {
        //             if (this.selectDataSource.cities[key].id == selected) {
        //                 this.registration.business.state = this.selectDataSource.cities[key].state_id;
        //             }
        //         }
        //     }
        // }
    },
    mounted() {
        console.log(this.registration.business.state);
        var source = {
            'type': [ 'states', 'cities' ],
            'country_id': "",
        };
        userData.getRegistrationFields(this.$route.query).then(response => {
            console.log(response.data)
            this.registration.personal = response.data.data;
            this.registration.business.country = response.data.data.country;
            this.customFields = response.data.fields;
            window.init_template();

            source.country_id = response.data.data.country;
            userData.getSelectData(source).then(response => {
                this.selectDataSource = response.data;
                this.citiesData = this.selectDataSource.cities;
                window.init_template();
            });
        }).catch(() => {
            router.push({
                name: 'home'
            });
        });
    },
    methods: {
        register() {
            console.log(this.registration);
            var data = {
                ...this.registration.personal,
                ...this.registration.business,
                ...this.registration.otp,
                ...this.registration.groupData,
            };
            this.showLoader(true);
                userData.register(data).then((response) => {
                    this.hideLoader();
                    this.$popper.success(response.data.message);
                    router.push({
                        name: 'home'
                    });
                },(error)=>{
                    this.hideLoader();
                    console.log(error);
                });
            
        },
        resendOTP() {
            var record = {
                'country': this.registration.personal.country,
                'phone': this.registration.personal.phone
            };
            this.showLoader(true);
            userData.resendOtp(record).then((response) => {
                this.hideLoader();
                this.$popper.success(response.data.message);
            })
        },
        nextTab() {
            this.currentTab = this.currentTab + 1;
        },
        prevTab() {
            this.currentTab = this.currentTab - 1;
        },
        assignStep(key, data) {
            this.registration.groupData = {
                ...this.registration.groupData,
                ...data
            };
            console.log(data)
            this.nextTab();
        }
    }
}