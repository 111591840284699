<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Register</h1>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style">
        <div class="content">

            <div class="progress rounded-xl mb-3 text-center" style="height:10px;">
                <div class="progress-bar rounded-xl border-0 primary-light-bg color-white text-start ps-2"
                    role="progressbar" :style="{'width': progress+'%'}" aria-valuenow="10" aria-valuemin="0"
                    aria-valuemax="100">
                    <!-- 20% -->
                </div>
            </div>
            <div class="tab-section collapse fade" :class="[currentTab == 1 ? 'show' : '']">
                <Form novalidate @submit="nextTab" :validation-schema="formValidations.personal" v-slot="{ errors }"
                    autocomplete="off">
                    <div class="col-12 mb-3">
                        <h1 class="font-20 letterSpacing font-500">Personal Information</h1>
                        <p class="primary-light-text letterSpacing mb-0">
                            Your basic personal information.
                        </p>
                        <!-- <p class="font-600 mb-n1">Follow the step and complete your registration process.</p> -->
                    </div>
                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.first_name}">
                        <i class="fa fa-user"></i>
                        <Field as="input" type="text" name="first_name" v-model="registration.personal.first_name"
                            class="form-control" id="firstName" placeholder="First Name"></Field>
                        <label for="firstName" class="color-primary-dark font-13 font-600">First Name</label>
                        <em>(required)</em>
                        <template v-slot:error>
                            {{errors.first_name}}
                        </template>
                    </form-control>

                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.last_name}">
                        <i class="fa fa-user"></i>
                        <Field as="input" type="text" name="last_name" v-model="registration.personal.last_name"
                            class="form-control" id="lastName" placeholder="Last Name"></Field>
                        <label for="lastName" class="color-primary-dark font-13 font-600">Last Name</label>
                        <em>(required)</em>
                        <template v-slot:error>
                            {{errors.last_name}}
                        </template>
                    </form-control>

                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.email}">
                        <i class="fa fa-at"></i>
                        <Field as="input" name="email" type="email" disabled v-model="registration.personal.email"
                            class="form-control" id="email" placeholder="Email Address"></Field>
                        <label for="email" class="color-primary-dark font-13 font-600">Email Address</label>
                        <em>(required)</em>
                        <template v-slot:error>
                            {{errors.email}}
                        </template>
                    </form-control>

                    <div class="input-group mb-3 raw">
                        <div class="col-4">
                            <form-control class="validate-field" :class="{'is-invalid' : errors.country}">
                                <app-select name="country" disabled="true" placeholder="Country"
                                    :options="$store.state.selectData.phoneCodes" value-prop="id"
                                    v-model="registration.personal.country">
                                    <template v-slot:default="slotProps">
                                        {{ slotProps.item.emoji }} &nbsp;(+{{ slotProps.item.phone_code }})
                                    </template>
                                    <template v-slot:display="slotProps">
                                        <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                                        {{ slotProps.item.name }} <span class="">(+{{ slotProps.item.phone_code
                                            }})</span>
                                    </template>
                                </app-select>
                                <template v-slot:error>{{errors.country}}</template>
                            </form-control>
                        </div>
                        <div class="col ms-2">
                            <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.phone}">
                                <i class="fa fa-mobile"></i>
                                <Field as="input" type="number" disabled name="phone"
                                    v-model="registration.personal.phone" class="form-control validate-number"
                                    id="phone" placeholder="Phone No"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    maxlength="15"></Field>
                                <label for="phone" class="color-primary-dark font-13 font-600">Phone No</label>
                                <em>(required)</em>
                                <template v-slot:error>
                                    {{errors.phone}}
                                </template>
                            </form-control>
                        </div>
                    </div>

                    <div class="mt-4">
                        <button type="button"
                            class="btn btn-s btn-ripple font-600 primary-light-bg letterSpacing rounded-s float-start"
                            v-if="currentTab != 1" @click="prevTab"> <i
                                class="fa fa-chevron-left me-2"></i>Prev</button>
                        <button class="btn btn-s btn-ripple font-600 primary-light-bg letterSpacing rounded-s float-end"
                            v-if="currentTab != totalTabs" type="submit">Next <i
                                class="fa fa-chevron-right ms-2"></i></button>
                    </div>
                </Form>
            </div>
            <div class="tab-section collapse fade" :class="[currentTab == 2 ? 'show' : '']">
                <Form novalidate @submit="nextTab" :validation-schema="formValidations.business" v-slot="{ errors }"
                    autocomplete="off">
                    <div class="col-12 mb-3">
                        <h1 class="font-20 letterSpacing font-500">Business Information</h1>
                        <p class="primary-light-text letterSpacing mb-0">
                            Business basic details.
                        </p>
                    </div>

                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.business_name}">
                        <i class="fa fa-briefcase"></i>
                        <Field as="input" type="text" name="business_name" v-model="registration.business.business_name"
                            class="form-control" id="businessName" placeholder="Business Name"></Field>
                        <label for="businessName" class="color-primary-dark font-13 font-600">Business Name</label>
                        <em>(required)</em>
                        <template v-slot:error>
                            {{errors.business_name}}
                        </template>
                    </form-control>

                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.business_address}">
                        <i class="fa fa-briefcase"></i>
                        <Field as="input" type="text" name="business_address" v-model="registration.business.address"
                            class="form-control" id="address" placeholder="Address"></Field>
                        <label for="address" class="color-primary-dark font-13 font-600">Address</label>
                        <em>(required)</em>
                        <template v-slot:error>
                            {{errors.business_address}}
                        </template>
                    </form-control>

                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.business_zipcode}">
                        <i class="fa fa-map-marker-alt"></i>
                        <Field as="input" type="number" name="business_zipcode" v-model="registration.business.zipcode"
                            class="form-control" id="zipcode" placeholder="Zipcode"></Field>
                        <label for="zipcode" class="color-primary-dark font-13 font-600">Zipcode</label>
                        <em>(required)</em>
                        <template v-slot:error>
                            {{errors.business_zipcode}}
                        </template>
                    </form-control>

                    <form-control class="validate-field" :class="{'is-invalid' : errors.business_country}">
                        <app-select name="business_country" disabled="true" placeholder="Country"
                            :options="$store.state.selectData.phoneCodes" value-prop="id"
                            v-model="registration.business.country">
                            <template v-slot:default="slotProps">
                                {{ slotProps.item.emoji }} &nbsp; {{ slotProps.item.name }}
                            </template>
                            <template v-slot:display="slotProps">
                                <span class="font-600 me-2">{{ slotProps.item.emoji }}</span> {{ slotProps.item.name }}
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.business_country}}</template>
                    </form-control>

                    <form-control class="validate-field" :class="{'is-invalid' : errors.state}">
                        <app-select name="state" placeholder="State" :options="selectDataSource.states" value-prop="id"
                            v-model="registration.business.state">
                            <template v-slot:default="slotProps">
                                <span class="font-600 me-2">{{ slotProps.item.text }}</span>
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.state}}</template>
                    </form-control>

                    <div v-if="registration.business.state">
                        <form-control class="validate-field" :class="{'is-invalid' : errors.city}">
                            <app-select name="city" placeholder="City" :options="citiesData" value-prop="id"
                                v-model="registration.business.city">
                                <template v-slot:default="slotProps">
                                    <span class="font-600 me-2">{{ slotProps.item.text }}</span>
                                </template>
                            </app-select>
                            <template v-slot:error>{{errors.city}}</template>
                        </form-control>
                    </div>
                    <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.business_website}">
                        <i class="fa fa-link"></i>
                        <Field as="input" type="text" name="business_website" v-model="registration.business.website"
                            class="form-control" id="businessWebsite" placeholder="Business Website"></Field>
                        <label for="businessWebsite" class="color-primary-dark font-13 font-600">Business
                            Website</label>
                        <em></em>
                        <template v-slot:error>
                            {{errors.business_website}}
                        </template>

                    </form-control>

                    <div class="mt-4">
                        <button type="button"
                            class="btn btn-s btn-ripple font-600 primary-light-bg letterSpacing rounded-s float-start"
                            v-if="currentTab != 1" @click="prevTab()"> <i
                                class="fa fa-chevron-left me-2"></i>Prev</button>
                        <button class="btn btn-s btn-ripple font-600 primary-light-bg letterSpacing rounded-s float-end"
                            v-if="currentTab != totalTabs" type="submit">Next <i
                                class="fa fa-chevron-right ms-2"></i></button>
                    </div>
                </Form>
            </div>
            <div v-for="(fields,key,index) in customFields" class="tab-section collapse fade"
                :class="[currentTab == (index+3) ? 'show' : '']">

                <registration-group :group="key" :fieldData="fields" @back="prevTab" @submit="assignStep(key,$event)">
                </registration-group>
            </div>

            <div class="tab-section collapse fade" :class="[currentTab == totalTabs ? 'show' : '']">
                <Form novalidate @submit="register" :validation-schema="formValidations.otp" v-slot="{ errors }"
                    autocomplete="off">
                    <div class="col-12 mb-3">
                        <h1 class="font-20 font-500 letterSpacing">Verify OTP</h1>
                        <p class="primary-light-text letterSpacing mb-0">
                            Final Step.
                        </p>
                    </div>

                    <div class="input-group mb-3 raw">
                        <div class="col-9">
                            <form-control class="validate-field" :class="{'is-invalid' : errors.phone_otp}">
                                <Field as="input" type="number" name="phone_otp" v-model="registration.otp.phone_otp"
                                    class="form-control" id="phoneOtp" placeholder="Phone OTP"></Field>
                                <label for="phoneOtp" class="color-primary-dark font-13 font-600">Phone OTP</label>
                                <em>(required)</em>
                                <template v-slot:error>
                                    {{errors.phone_otp}}
                                </template>
                            </form-control>
                        </div>
                        <div class="col-3">
                            <button type="button" class="btn btn-sm btn-ripple text-primary" @click="resendOTP()">Resend
                                OTP</button>
                        </div>
                    </div>

                    <form-control class="validate-field" inputType="checkbox" :class="{'is-invalid' : errors.agree}">
                        <div class="form-check icon-check">
                            <Field as="input" type="checkbox" name="agree" value="true" v-model="registration.otp.agree"
                                class="form-check-input" id="agree"></Field>
                            <label for="agree" class="form-check-label">I agree to all the <a href="#">Lifely terms and
                                    conditions.</a></label>
                            <i class="icon-check-1 fa fa-square color-gray-dark font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 color-highlight"></i>
                        </div>
                        <template v-slot:error>
                            {{errors.agree}}
                        </template>
                    </form-control>

                    <div class="mt-4">
                        <button type="button"
                            class="btn btn-s btn-ripple font-600 primary-light-bg letterSpacing rounded-s float-start"
                            v-if="currentTab != 1" @click="prevTab()"> <i
                                class="fa fa-chevron-left me-2"></i>Prev</button>
                        <button class="btn btn-s btn-ripple font-600 primary-light-bg letterSpacing rounded-s float-end"
                            v-if="currentTab == totalTabs" type="submit">Register</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</div>